<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">工作流列表</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>

    <v-row no-gutters>
      <v-col class="mr-3" cols="5">
        <v-text-field
          dense
          label="输入工作流名称查询"
          solo
          @change="changeSearch"
        ></v-text-field>
      </v-col>
      <v-col cols="5">
        <v-btn color="primary" @click="doGet(1)">查询</v-btn>
      </v-col>
    </v-row>

    <v-card flat min-height="300" tile>
      <v-simple-table v-if="refresh">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">工作流名称</th>
              <th class="text-left">工作流类型</th>
              <th class="text-left">工单号前缀</th>
              <th class="text-left">工作流限制</th>
              <th class="text-left">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-show="table_data.length <= 0">
              <td colspan="4" style="text-align: center">
                暂无数据
              </td>
            </tr>
            <tr
              v-for="(item, index) in table_data"
              :key="item.name"
              :style="index % 2 === 0 ? 'background: rgb(242,242,242)' : ''"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.ticket_sn_prefix }}</td>
              <td>{{ item.limit_expression }}</td>
              <td>
                <v-btn color="primary" small @click="editItem(item.id)"
                  >编辑</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-btn color="primary" small style="margin-top: 10px">新增</v-btn>
    <div v-if="refresh" class="pagination">
      <v-pagination
        v-if="table_data.length > 0"
        v-model="search.page"
        :length="pageCount"
        @input="(e) => doGet(e)"
      ></v-pagination>
    </div>
  </v-container>
</template>
<script>
import { getWorkflowList } from "@/api/workflows";

export default {
  name: "workflows",
  mounted() {
    this.doGet();
  },
  methods: {
    doGet(page = this.search.page) {
      console.log(this.search);
      getWorkflowList({ ...this.search, page }).then((res) => {
        this.table_data = res.results;
        this.total = res.count;
        this.refresh = false;
        this.$nextTick(() => {
          this.refresh = true;
        });
      });
    },
    changeSearch(e) {
      this.search.name = e;
    },

    editItem(id) {
      this.$router.push({ name: "edit_workflow", params: { id } });
    },
  },
  data: () => ({
    refresh: true,
    search: {
      name: "",
      page: 1,
      pagesize: 10,
    },
    total: 0,
    table_data: [],
  }),
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.search.pagesize);
    },
  },
};
</script>
<style lang="stylus" scoped></style>
